<template>
  <div class="zoom-buttons text-left">
    <i
      class="fa fa-plus-square"
      @click="svg.zoomIn()"
    />
    <br>
    <i
      class="fa fa-minus-square"
      @click="svg.zoomOut()"
    />
  </div>
</template>

<script>
import svgPanZoom from 'svg-pan-zoom'
import hammerHandler from './hammer_handler'

export default {
  name: 'DerbyZoom',
  data() {
    return {
      svg: null
    }
  },
  mounted() {
    this.initializeSvg()
    window.addEventListener('resize', () => { this.initializeSvg() })
  },
  methods: {
    initializeSvg() {
      if(this.svg != null) {
        this.svg.destroy()
      }
      this.svg = svgPanZoom('#svg-seating-chart', { zoomEnabled: true, customEventsHandler: hammerHandler })
    }
  }
}
</script>
