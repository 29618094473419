const isMobileNav = () => $('.check-for-mobile').css('float') === 'none'
const isTablet = () => window.outerWidth > 767 && window.outerWidth < 1025

document.addEventListener('turbo:load', () => {
  let slideWrapper = document.querySelector('.slide-up-down-outer-wrapper')
  if(slideWrapper) {
    let activeElemsCnt = slideWrapper.dataset.elements
    let elems = document.querySelectorAll('.slide-up-down-outer-wrapper .slide-up-down-wrapper')
    let toggleBtnState = false
    let toggleBtn = document.querySelector('.load-more.toggle-btn')

    if (elems && activeElemsCnt) {
      elems.forEach((elem, index) => {
        if(index < activeElemsCnt) {
          elem.classList.remove('collapsed')
        }
      })
      
      if (toggleBtn) {
        toggleBtn.addEventListener('click', (event) => {
          event.preventDefault()
          elems.forEach((elem, index) => {
            if(index >= activeElemsCnt) {
              elem.classList.toggle('collapsed')
            }
          })
  
          if(!isMobileNav() && !isTablet()) {
            if(document.querySelector('.slide-up-down-outer-wrapper .slide-up-down-wrapper.collapsed')) {
              let scrollHeader = document.querySelector('.scroll-header')

              if(scrollHeader) {
                let scrollHeaderTop = scrollHeader.getBoundingClientRect().top - 75 //navHeight
                setTimeout(() => {
                  window.scrollBy({
                    top: scrollHeaderTop,
                    behavior: 'smooth'
                  })
                }, 400)
              }
            } else {
              setTimeout(() => {
                window.scrollBy({
                  top: slideWrapper.getBoundingClientRect().top - 100,
                  behavior: 'smooth'
                })
              }, 400)
            }
          }
  
          // hide/show text for load more button
          toggleBtn.classList.add('inactive')
          const arrowElem = toggleBtn.querySelector('.arrow-wrapper')
          setTimeout(() => {
            if(toggleBtnState) {
              toggleBtn.textContent = toggleBtn.dataset.showText
            } else {
              toggleBtn.textContent = toggleBtn.dataset.hideText
            }
  
            toggleBtn.classList.remove('inactive')
            toggleBtnState = !toggleBtnState
  
            if(arrowElem) {
              toggleBtn.appendChild(arrowElem)
              arrowElem.classList.toggle('flipped')
            }
          }, 500)
        })
      }
    }
  }
})
