import Lightbox from 'bs5-lightbox'

document.addEventListener('turbo:load', () => {
  addLightboxListeners()
  displaySeatingLinkLightbox()
})

function addLightboxListeners() {
  let lightboxes = document.querySelectorAll('[data-toggle="custom-lightbox"]')

  Array.from(lightboxes).forEach(box => {
    box.addEventListener('click', function(event) {
      event.preventDefault()
      let lightbox = new Lightbox(box)
      lightbox.show()
    })
  })
}

function displaySeatingLinkLightbox() {
  let box = document.querySelector('.seating--link')
  if(box && box.dataset.toggle != 'custom-lightbox') { // dont duplicate
    box.addEventListener('click', (e) => {
      e.preventDefault()
      let lightbox = new Lightbox(box)
      lightbox.show()
    })
  }
}