import { createApp } from 'vue'
import Zoom from './zoom.vue'

document.addEventListener('turbo:load', () => {
  if (document.getElementById('zoom')) {
    const app = createApp({
      el: '#zoom',
      // Async loading the zoom component
      components: { Zoom },
      template: '<Zoom/>'
    })
    app.mount(document.getElementById('zoom'))
  }
})



