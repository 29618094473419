import  { setTooltip } from '../shared/tooltip'

window.tooltipReference = null
window.hoverable = true

const tooltipSetter = (tr) => {
  var package_positions = $(tr).find('.ticket-label').attr('data-position')

  if(package_positions) {
    let pkg_positions = package_positions.replace(/'/g, '"')
    pkg_positions = JSON.parse(pkg_positions)

    var seatingChartId = document.getElementsByClassName('seatingChartPackage')[0].getAttribute('data-seating-chart-id')
    pkg_positions = pkg_positions[seatingChartId]

    let keys = Object.keys(pkg_positions)

    if(keys.length == 1) {
      let tooltips = $('[id^=svg-tooltip]')
      for (let tooltip of tooltips) {
        if(tooltip.id != 'svg-tooltip') {
          $(tooltip).remove()
        }
      }
    }

    let key = keys[0]
    setTooltip(document.getElementById(pkg_positions[key][0]))

    for (let i=1; i<keys.length; i++) {
      key = keys[i]
      setTooltip(document.getElementById(pkg_positions[key][0]), i)
    }
  }
}


$(document)
  .on('mouseover', '.group-package', function () {

    window.tooltipReference = this
    if(window.hoverable)
      tooltipSetter(this)
  })
