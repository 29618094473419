const gsap = window.gsap
const ScrollTrigger = window.ScrollTrigger

document.addEventListener('turbo:load', ()=> {

  if(document.getElementById('scrollCounter')){
    gsap.registerPlugin(ScrollTrigger)

    const numbers = document.querySelectorAll('.counters')

    gsap.utils.toArray('.counter').forEach(function (el) {

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: numbers,
          start: 'top 80%',
          // markers: true,
          // toggleActions: 'restart pause resume pause',
        },
      })


      const target = { val: 0 }
      tl.to(target, {
        val: el.getAttribute('data-number'),
        duration: 3,
        stagger: 0,
        onUpdate: function () {
          el.innerText = target.val.toLocaleString(window.locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        },
      })
    })
  }
})

