import PriceFilter from '../../shared/price_filter.vue'
import VueLoader from '../../default/vue-loader'

let primary_color = '#ec1d50' //Default brand color
let sec_color = '#ccc'
let hidingPackageClass = '.group-package'

let checker = (categories, pkg_types) => pkg_types.every(pkg_type => categories.includes(pkg_type))

const addVueListener = function() {
  VueLoader.loadVueComponent(PriceFilter, document.getElementById('seating_chart_price'))
}

//Price filter
const hideAllPackages = () => {
  $('.leftside .product-item').each(function () {
    $(this).closest(hidingPackageClass).addClass('hidden')
  })
}

const filterPackages = (pkg_types) => {
  Array.from(document.querySelectorAll('.product-item')).forEach( (elem) => {
    let categories = elem.getAttribute('data-category-names').split(',')
    if(checker(categories, pkg_types)) {
      highlightSections(elem)
      elem.parentElement.classList.remove('pkg-hidden')
    }
  })
}

const filterPackagesRangeSlider = (minVal, maxVal) => {

  hideAllPackages()

  $('.leftside .product-item').each(function () {
    let price = parseInt($(this).attr('data-price'))

    if (minVal <= price && maxVal >= price) {
      $(this).closest(hidingPackageClass).removeClass('hidden')
    }
  })
  updateAvailabilityCounter()
}

const disableSections = () => {
  Array.from(document.querySelectorAll('[data-toggle=svg-tooltip]')).forEach((elem) => {
    $(elem).css('fill', '#ccc')
  })
}

const get_section_ids = (section_ids_json) => {
  let sec_ids = section_ids_json.replace(/'/g, '"')
  sec_ids = JSON.parse(sec_ids)

  let seatingChartId = document.getElementsByClassName('seatingChartPackage')[0].getAttribute('data-seating-chart-id')
  sec_ids = sec_ids[seatingChartId]

  return Object.values(sec_ids).flat()
}

export function highlightSections(elem) {
  let section_ids_json = $(elem).find('.ticket-label').attr('data-position')
  let section_ids = []
  if (section_ids_json) {
    section_ids = get_section_ids(section_ids_json)
  }

  Array.from(document.querySelectorAll('[data-toggle=svg-tooltip]')).forEach( (elem) => {
    if(section_ids.includes(elem.getAttribute('id')))
      $(elem).css('fill', $(elem).attr('data-fill-color'))
    $(elem).removeClass('disableClick')
  })
}

const enableDisableResetButton = (hiddenPkgCount) => {
  if (hiddenPkgCount == 0)
    $('.redesign-wrapper .reset').prop('disabled', true)
  else
    $('.redesign-wrapper .reset').prop('disabled', false)
}

$(document)
  .on('change', '.container-fluid .slider__min-slider', function () {
    $('.min-slider').blur()
    $('.max-slider').blur()

    const minVal = parseInt($(this).val())
    const maxVal = parseInt($('.slider__max-slider').val())

    filterPackagesRangeSlider(minVal, maxVal)
    positionSeatingChart()
  })

  .on('keyup', '.container-fluid .min-slider', function (event) {
    if (event.keyCode == 13) {
      const minVal = parseInt($(this).val().slice(1))
      const maxVal = parseInt($('.slider__max-slider').val())
      $('.min-slider').blur()
      $('.max-slider').blur()

      filterPackagesRangeSlider(minVal, maxVal)
    }
  })

  .on('change', '.container-fluid .slider__max-slider', function () {
    $('.min-slider').blur()
    $('.max-slider').blur()

    const minVal = parseInt($('.slider__min-slider').val())
    const maxVal = parseInt($(this).val())

    filterPackagesRangeSlider(minVal, maxVal)
    positionSeatingChart()
  })

  .on('keyup', '.container-fluid .max-slider', function (event) {
    if (event.keyCode == 13) {
      const minVal = parseInt($('.slider__min-slider').val())
      const maxVal = parseInt($(this).val().slice(1))
      $('.min-slider').blur()
      $('.max-slider').blur()

      filterPackagesRangeSlider(minVal, maxVal)
    }
  })


//Reset filter
export function resetPackageType() {
  $('.product-item').each(function () {
    $(this).parent().removeClass('pkg-hidden')
    $(this).parents('.seatingChartPackage').removeClass('hidden')
  })
}

const resetPriceFilter = () => {
  $('.slider__min-slider').val(document.getElementsByClassName('slider__min-slider')[0].min)
  $('.slider__max-slider').val(document.getElementsByClassName('slider__max-slider')[0].max)

  $('.slider__max-slider').css('background', primary_color)

  $('.product-item').each(function () {
    $(this).parent().removeClass('hidden')
  })
  VueLoader.loadVueComponent(PriceFilter, document.getElementById('seating_chart_price'))
}


const resetPackagesBySection = () => {
  $('.product-item').each(function () {
    $(this).parent().removeClass('pkg-sec-hidden')
  })

  removeRedFill()

}

let removeRedFill = () => {
  $('[data-toggle=svg-tooltip]').each(function () {
    $(this).removeClass('red-fill')
  })
}


$(document)
  .on('click', '.reset', function () {
    resetPriceFilter()
    resetPackageType()
    resetPackagesBySection()
    resetHighlightSections()
    pkg_types = []

    $('.package-type-filter .dropdown-menu .switch input').each(function () {
      $(this).prop('checked', false)
    })

    $('.buttons-wrapper .switch input').each(function () {
      $(this).prop('checked', false)
    })

    $('.group .seatingChartPackage').each(function() {
      $(this).removeClass('hidden')
    })
    enableDisableFilters()
    updateAvailabilityCounter()
  })

let pkg_types = []
//Package type filter
$(document)
  .on('change', '.container-fluid input[type=checkbox]', function () {
    pkg_types = []
    let data_categories = []
    let elems = []

    if(screen.width <= 768) {
      elems = $('.visible-mobile .package-type-filter li[data-filter]')
    } else {
      elems = $('.package-type-filter:eq(1) li[data-filter]')
    }

    elems.each(function() {
      let pkg_type = $(this).attr('data-filter')

      if(pkg_type) {
        let isChecked = $(this).find('input[type=checkbox]').is(':checked')

        if(isChecked) {
          pkg_types.push(pkg_type)
        } else {
          let index = pkg_types.indexOf(pkg_type)
          if(index > 0) {
            pkg_types.splice(index, 1)
          }
        }
      }
      pkg_types = Array.from(new Set(pkg_types))
    })
    if (pkg_types.length == 0) {
      resetPackageType()
      resetHighlightSections()
      updateAvailabilityCounter()
      enableDisableFilters(data_categories)
      addVueListener()
    } else {
      $('.product-item').each(function () {
        $(this).parent().addClass('pkg-hidden')
      })

      disableSections()
      filterPackages(pkg_types)
      Array.from(document.querySelectorAll('.product-item')).forEach( (elem) => {
        if(!($(elem).parent().hasClass('pkg-hidden'))) {
          let categories = elem.getAttribute('data-category-names').split(',')
          data_categories.push(categories)
        }
      })
      data_categories = data_categories.flat()
      data_categories = Array.from(new Set(data_categories))
      positionSeatingChart()
      enableDisableFilters(data_categories)
      updateAvailabilityCounter()
      addVueListener()
    }
  })

export function positionSeatingChart() {
  const map = document.querySelector('.map')
  const offset = window.innerHeight * 0.15
  const mapPosition = map.getBoundingClientRect().top + window.scrollY - offset
  window.scrollTo({
    top: mapPosition,
    behavior: 'smooth'
  })
}
  
export function resetHighlightSections() {
  Array.from(document.querySelectorAll('[data-toggle=svg-tooltip]')).forEach((elem) => {
    $(elem).css('fill', $(elem).attr('data-fill-color'))
    $(elem).removeClass('disableClick')
  })
}

const enableDisableFiltersOptions = (data_categories) => {
  Array.from(document.querySelectorAll('.options-wrapper .options .option')).forEach((option) => {
    option.classList.add('disabled')
  })

  Array.from(document.querySelectorAll('.options-wrapper .options .option')).forEach((option) => {
    let data_filter = option.getAttribute('data-filter')

    if(data_categories.length == 0) {
      option.classList.remove('disabled')
    } else {
      if(data_categories.includes(data_filter))
        option.classList.remove('disabled')
      else
        option.classList.add('disabled')
    }
  })
}

export function enableDisableFilters(data_categories = []) {
  enableDisableFiltersOptions(data_categories)

  Array.from(document.querySelectorAll('.package-type-filter li[data-filter]')).forEach((elem) => {
    let data_filter = elem.getAttribute('data-filter')
    let slider = elem.querySelector('.package-type-slider')

    if (data_categories.length == 0) {
      elem.querySelector('input[type=checkbox]').disabled = false
      slider.classList.remove('disabled')
      slider.parentElement.parentElement.classList.remove('disabled')
    } else {
      if (data_categories.includes(data_filter)) {
        elem.querySelector('input[type=checkbox]').disabled = false
        slider.classList.remove('disabled')
        slider.parentElement.parentElement.classList.remove('disabled')
      } 
      else {
        elem.querySelector('input[type=checkbox]').disabled = true
        slider.classList.add('disabled')
        slider.parentElement.parentElement.classList.add('disabled')
      }
    }
  })
}
let isRedesign = () => !!$('.redesign-wrapper .map-wrapper .mobile-packages-filter-heading')
let mobileFilterBtn = () => isRedesign ?
  '.map-wrapper .packages-filter .filter-btn.mobile-only' : '.mobile-packages-filter-header .filter-heading .btn-inverse'

//mobile-packages-filter
$(document)
  .on('click', mobileFilterBtn(), function () {
    $('.packages-filter .mobile-packages-filter').addClass('open')
    if (isRedesign()) $('.redesign-wrapper .filters .secondary-filters').addClass('open')
  })

  .on('click', '.mobile-packages-filter .icon-cross', function () {
    $('.packages-filter .mobile-packages-filter').removeClass('open')
    if (isRedesign()) $('.redesign-wrapper .filters .secondary-filters').removeClass('open')
  })


//Keep dropdown to open even on click
$(document)
  .on('click', '.package-type-filter .dropdown-menu .switch', function (event) {
    event.stopPropagation()
  })



//range price slider
const sliderColor = () => {

  let minThumbVal = $('.slider__min-slider').val()
  let maxThumbVal = $('.slider__max-slider').val()

  let minVal = document.getElementsByClassName('slider__max-slider')[0].min
  let maxVal = document.getElementsByClassName('slider__max-slider')[0].max

  let minP = ((minThumbVal - minVal) / (maxVal - minVal)) * 100

  let maxP = ((maxThumbVal - minVal) / (maxVal - minVal)) * 100

  $('.slider__max-slider').css('background', 'linear-gradient(to right, ' + sec_color + ', ' + sec_color + ' ' + minP + '%, ' + primary_color + ' ' + minP + '%, ' + primary_color + ' ' + maxP + '%, ' + sec_color + ' ' + maxP + '%)')
}

$(document)
  .on('input', '.slider__min-slider', function () {
    sliderColor()
  })

  .on('input', '.slider__max-slider', function () {
    sliderColor()
  })

  
const filterPackagesBySection = () => {
  let sections = []
  Array.from(document.querySelectorAll('.product-item')).forEach((elem) => {
    elem.parentElement.classList.add('pkg-sec-hidden')
  })

  Array.from(document.querySelectorAll('.rightsidemap [data-toggle=svg-tooltip]')).forEach( (elem) => {
    if(elem.classList.contains('red-fill')) {
      sections.push(elem.getAttribute('id'))
    }
  })

  Array.from(document.querySelectorAll('.leftsidemap [data-toggle=svg-tooltip]')).forEach( (elem) => {
    if(elem.parentElement.classList.contains('red-fill')) {
      sections.push(elem.getAttribute('id'))
    }
  })

  Array.from(document.querySelectorAll('.product-item')).forEach( (elem) => {
    let section_ids_json = elem.getElementsByClassName('ticket-label')[0].getAttribute('data-position')
    let section_ids = []
    if (section_ids_json) {
      section_ids = get_section_ids(section_ids_json)
    }

    for (let section_id of section_ids) {
      if (sections.includes(section_id)) {
        elem.parentElement.classList.remove('pkg-sec-hidden')
      }
    }
  })
  positionSeatingChart()
  updateAvailabilityCounter()
}

const anchorPageToPackage = () => {
  let elements = document.querySelectorAll('[data-toggle=svg-tooltip]')
  let nodesListArray = Array.from(elements)
  let selectedSection = nodesListArray.find((elem) => {
    return (elem.classList.contains('red-fill'))
  })

  let productItems = document.querySelectorAll('.product-item')
  let nextNodesListArray = Array.from(productItems)

  let firstSelectedPackage = nextNodesListArray.find((elem) => {
    let section_ids_json = elem.getElementsByClassName('ticket-label')[0].getAttribute('data-position')
    let section_ids = []
    if (section_ids_json) {
      section_ids = get_section_ids(section_ids_json)
    }

    let firstMatch = section_ids.find(section_id => {
      if (selectedSection['id'] == section_id) {
        return elem.parentElement
      }
    })
    if (firstMatch) { return firstMatch }
  })
  // scroll to top of firstSelectedPackage
  const yOffset = -130
  const y = firstSelectedPackage.getBoundingClientRect().top + window.pageYOffset + yOffset
  window.scrollTo({ top: y, behavior: 'smooth' })
}

document.addEventListener('turbo:load', () => {
  Array.from(document.querySelectorAll('.rightsidemap [data-toggle=svg-tooltip]')).forEach( (elem) => {
    elem.addEventListener('click', () => {
      if (elem.classList.contains('red-fill')) {
        elem.classList.remove('red-fill')
      } else {
        elem.classList.add('red-fill')
      }

      let filter =  false
      $('.rightsidemap [data-toggle=svg-tooltip]').each(function() {
        if($(this).hasClass('red-fill')) {
          filter = true
        }
      })

      let ticketSeatingChart = document.getElementById('f1-ticket-seating-chart')
      if (ticketSeatingChart) {
        anchorPageToPackage()
        removeRedFill()
      }
      else {
        if (filter) {
          filterPackagesBySection()
        } else {
          resetPackagesBySection()
        }
      }
      updateAvailabilityCounter()
    })
    updateAvailabilityCounter()
  })
})

document.addEventListener('turbo:load', () => {
  Array.from(document.querySelectorAll('.leftsidemap [data-toggle=svg-tooltip]')).forEach( (elem) => {
    elem.addEventListener('click', () => {
      if(elem.parentElement.classList.contains('red-fill')) {
        elem.parentElement.classList.remove('red-fill')
      } else {
        elem.parentElement.classList.add('red-fill')
      }

      let filter =  false
      $('.leftsidemap [data-toggle=svg-tooltip]').each(function() {
        if($(this).parent().hasClass('red-fill')) {
          filter = true
        }
      })

      if(filter) {
        filterPackagesBySection()
      } else {
        resetPackagesBySection()
      }
    })
  })
})

document.addEventListener('turbo:load', () => {
  if(document.getElementsByClassName('experience-ticket-hotel-bar').length > 0){
    if(document.getElementById('f1-ticket-seating-chart')){
      document.getElementsByClassName('ticket-statement-wrapper')[0].classList.add('active')
    } else {
      document.getElementsByClassName('experience-statement-wrapper')[0].classList.add('active')
    }
  }
})
const hiddenPackageCount = () => {
  let counter = 0
  $('.seatingChartPackage').each((i, pkg) => {
    if ($(pkg).hasClass('hidden') || $(pkg).children('.group-package')[0].classList.value.includes('hidden')) {
      counter++
    }
  })
  return counter
}

const availabilityCount = () =>{
  if ($('.seatingChartPackage .group-package').length <= hiddenPackageCount()) {
    return 0
  } else {
    return $('.seatingChartPackage .group-package').length - hiddenPackageCount()
  }
}

const updateAvailabilityCounter = () =>{
  $('.redesign-wrapper .sorting-wrapper .available .dark-text').text(`${availabilityCount()} Options Available`)
  enableDisableResetButton(hiddenPackageCount())
}


$(document)
  .on('turbo:load', function () {
    let elem = document.getElementById('seating_chart_price')
    if(elem) {
      let color = window.getComputedStyle(elem, null).getPropertyValue('--slider-primary-color')
      primary_color = color ? color : '#e10600'

      color = window.getComputedStyle(elem, null).getPropertyValue('--slider-secondary-color')
      sec_color = color ? color : '#ccc'
    }
  })

$(document).on('turbo:load', function () {
  $('.sorting-wrapper .sort select').on('change', (e) => {
    if (!['htol', 'ltoh'].includes(e.target.value)) return
    let items = document.querySelectorAll('.product-item')

    let sortedItems = Array.from(items).sort((a, b) => {
      return parseFloat(a.dataset.price) - parseFloat(b.dataset.price)
    })

    if ($('.sorting-wrapper .sort select').val() == 'htol') sortedItems.reverse()

    sortedItems.forEach((item, index) => {
      item.parentElement.parentElement.style.order = index
    })
  })
})

$(document).on('turbo:load', function () {
  $('.redesign-wrapper .options-wrapper .option').on('click', (e) => {
    e.preventDefault()
    let option = e.currentTarget

    if(!option.classList.contains('disabled')) {
      let pkg_type = e.target.parentElement.dataset['filter']

      $('.secondary-filter-wrapper .dropdown-menu li').each((i, elem) => {
        if (elem.dataset['filter'] == pkg_type) $(elem).find('.switch input').click()
      })
    }
  })
})

document.addEventListener('turbo:load', () => {
  addVueListener()
})
