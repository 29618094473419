document.addEventListener('turbo:before-render', function(event) {
  if (!event.detail.reload) {
    document.documentElement.classList.add('no-smooth-scroll')
  }
})

document.addEventListener('turbo:load', () => {
  setTimeout(function() {
    document.documentElement.classList.remove('no-smooth-scroll')
  }, 500)
})
