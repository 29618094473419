import ScrollClass from '../shared/scrolling_class'

const findPrice = row => Number($(row).find('.price').data('money')) || -1
function showPrice(priceRange) {
  $('.ticket-package-table').removeClass('hidden')
  //"[2500,3499]"
  ScrollClass.scrollToClass('scroll-on-click')

  $('.replaceable-table tr').each(function() {
    let middle
    if ((priceRange[0] <= (middle = findPrice($(this))) && middle <= priceRange[1]) && $(this).
      hasClass('hidden')) {
      $(this).removeClass('hidden')
    }
  })
}

function hidePrice(priceRange) {
  $('.replaceable-table tr').each(function() {
    let middle
    if ((priceRange[0] <= (middle = findPrice($(this))) && middle <= priceRange[1]) && !$(this).
      hasClass('hidden')) {
      $(this).addClass('hidden')
    }
  })
}

function resetFilters() {
  $('.ticket-package-table, .replaceable-table tr').addClass('hidden')
  $('.category-seating-chart .price_range').prop('checked', false)
}

document.addEventListener('turbo:load', () => {
  let checkboxes = Array.from(document.getElementsByClassName('checkbox-row'))
  if(checkboxes.length > 0){
    checkboxes.forEach(checkbox => {
      let checkboxInput = checkbox.getElementsByTagName('input')[0]
      if(checkboxInput.checked){
        showPrice($(checkboxInput).data('range'))
        $.updateSVG()
        $.changeTableColors()
      }
    })
  }
  // if
})

$(document)
  .on('turbo:load', () =>
    $('.category-seating-chart .price_range').click(function() {
      if ($(this).is(':checked')) {
        showPrice($(this).data('range'))
      } else {
        hidePrice($(this).data('range'))
      }
      $.updateSVG()
      $.changeTableColors()
    })
  )
  .on('turbo:load', () =>
    $.updateSVG()
  )
  .on('click', '.category-seating-chart .reset-filters', function() {
    resetFilters()
    $.updateSVG()
    $.changeTableColors()
  })
