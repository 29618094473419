$(document).on('turbo:load', function () {
  $('.seating_chart .options-wrapper .option').on('click', (e) => {
    e.preventDefault()

    let pkg_type = e.target.closest('[data-filter], .option').dataset['filter']

    $('.secondary-filter-wrapper .dropdown-menu li').each((i, elem) => {
      if (elem.dataset['filter'] == pkg_type) $(elem).find('.switch input').click()
    })
  })
})
