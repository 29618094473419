import 'bootstrap-sass/assets/javascripts/bootstrap/tooltip'

$.tableRowsList = categoryName =>
  $(`[data-category-names*='${categoryName}']`).filter(function() {
    return $(this).data('categoryNames').indexOf(categoryName) > -1
  })

$.changeTableColors = function() {
  $('.replaceable-table tr').removeClass('row-color')
  $('.replaceable-table tr').not('.hidden').filter(':even').addClass('row-color')
}

$.updateSVG = function() {
  $('.svg-image svg > g, .svg-pan-zoom_viewport > g').removeClass('active')

  $('.svg-image svg > g, .svg-pan-zoom_viewport > g').each(function() {
    if ($($.tableRowsList($(this).attr('id'))).not('.hidden').length >= 1) {
      $(this).addClass('active')
    }
  })
}

function initializeSeatingChart() {
  $('.svg-image svg > g, .svg-pan-zoom_viewport > g').length
  $('.svg-image svg > g, .svg-pan-zoom_viewport > g').each(function() {
    let category = $(this).attr('id')
    let tableRows = $.tableRowsList(category)

    if (tableRows.length >= 1) {
      $(this).attr('rel', 'tooltip').
        attr('title', $(`.svg-image .${category}`).data('text')).
        addClass('available').
        removeClass('unavailable')

      if ($(tableRows).find('.available').length >= 1) {
        $(this).find('.sold_out').css('display', 'none')
      }
    }

    if ($(this).hasClass('hospitality')) {
      var hospitalityId = $(this).attr('id')
      $(this).attr('rel', 'tooltip').
        attr('title', $(`.svg-image .${hospitalityId}`).data('text'))
    }
  })

  $('[rel=tooltip]').tooltip({
    html: true,
    container: 'body',
    placement(tooltip, source) {
      setTimeout((() =>
        $(tooltip).css('top', `${$(source).position().top - $(tooltip).height() - 5}px`)
      ), 0)
      return 'top'
    }
  })
}

function updateSvgForBackButton(){
  if($('.price_range').is(':checked')){return}
  let sections = Array.from(document.getElementById('svg-seating-chart').children)
  sections.forEach(section => {
    Array.from(section.getElementsByClassName('active')).forEach(activeSection => {
      showTableRows($.tableRowsList($(activeSection).attr('id')))
      $.changeTableColors()
    })
  })
}

function showTableRows(tableRows){
  $('.ticket-package-table').removeClass('hidden')
  $(tableRows).removeClass('hidden')
}

function updateTable(seatingArea) {
  const tableRows = $.tableRowsList(seatingArea.attr('id'))

  $(seatingArea).toggleClass('active')
  if (seatingArea.hasClass('active')) {
    showTableRows(tableRows)
  } else {
    $(tableRows).addClass('hidden')
  }
}

$(document)
  .on('turbo:load', function() {
    if (!$('.category-seating-chart #svg-seating-chart').length) { return }

    initializeSeatingChart()
    $.changeTableColors()

    updateSvgForBackButton()
  })
  .on('click', 'category-seating-chart .svg-image svg, .svg-pan-zoom_viewport > g', function() {
    updateTable($(this))
    $.changeTableColors()
  })
