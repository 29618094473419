import {sizeNavbar} from './navbar_functions'
import FastClick from 'fastclick'

document.addEventListener('turbo:load', () => {
  const language = document.getElementsByClassName('icon-language')[0]
  const account = document.getElementsByClassName('icon-account')[0]
  const phone = document.getElementsByClassName('icon-phone')[0]
  const search = document.getElementsByClassName('icon-search')[0]
  const subnavDropdowns = document.getElementsByClassName('navbar-dropdown')
  const subnavBack = document.getElementsByClassName('subnav-back')[0]
  const accountSubnavBack = document.getElementsByClassName('account-subnav-back')[0]
  const secondMenus = document.getElementsByClassName('secondary-menu')
  const menus = document.querySelectorAll('.navbar-dropdown')
  const phoneDropdown = document.querySelector('.phone-number span')
  const searchDropdown = document.getElementsByClassName('search-box')[0]
  const languageDropdown = document.getElementsByClassName('language-menu')[0]
  const accountDropdown = document.getElementsByClassName('account-box')[0]
  const originals = document.getElementsByClassName('original')
  const accountIcon = document.querySelector('.account-icon')
  const navItems = document.getElementsByClassName('original navbar-item')
  const back = document.getElementById('account-back-container')
  const navLinks = document.getElementsByClassName('nav-link')
  const backIcon = document.querySelector('.secondary-menu .back-icon')
  const contact = document.getElementsByClassName('contact-us')
  const navbarItems = document.getElementsByClassName('navbar-item')
  const navLabels = document.getElementsByClassName('nav-label')
  const mobileAccount = document.getElementsByClassName('mobile-account')
  const middleNav = document.querySelector('.middle-nav')
  const navbarToggler = document.querySelector('.navbar-toggler')
  const phoneMenu = document.querySelector('.phone-menu')

  const isDerby = () => window.name == 'derby'
  const supercars = () => window.name == 'supercars'
  const sglmg = () => window.name == 'sglmg'
  const isMobile = () => window.innerWidth < 992

  let navbarHeight = undefined

  const isCollapsible = () => {
    if(isDerby()) {
      return true
    } else {
      return $('main').height() > $(window).height()
    }
  }
  
  function updateCollapse() {
    if (!isCollapsible()) { return }
  
    const body = $('body')
    const collapsed = body.hasClass('navbar-collapsed')
    const toggle = (collapsed && (window.pageYOffset < navbarHeight)) ||
      (!collapsed && (window.pageYOffset >= navbarHeight))

    if (toggle) { body.toggleClass('navbar-collapsed') }
    sizeNavbar()
  }

  if (typeof FastClick !== 'undefined') {
    FastClick.attach(document.body)
  }

  if (document.querySelector('.navbar').offsetParent !== null) {
    navbarHeight = document.querySelector('.navbar').offsetHeight
  }
  

  document.addEventListener('scroll', updateCollapse)


  if(accountSubnavBack) {
    accountSubnavBack.addEventListener('click', () => {
      if(!supercars() && !sglmg()) {
        Array.from(subnavDropdowns).forEach(subnavDropdown => {
          subnavDropdown.classList.toggle('child-expanded')
        })

        Array.from(navItems).forEach(navItem => {
          navItem.classList.toggle('child-expanded')
        })

        Array.from(navLinks).forEach(navLink => {
          navLink.classList.toggle('child-expanded')
        })
      }
    }
    )}
  
  if(accountIcon) {
    accountIcon.addEventListener('click', () => {
      if((!supercars() && !sglmg()) && isMobile()) {
        Array.from(subnavDropdowns).forEach(subnavDropdown => {
          subnavDropdown.classList.toggle('child-expanded')
        })

        Array.from(navItems).forEach(navItem => {
          navItem.classList.toggle('child-expanded')
        })
  
        Array.from(navLinks).forEach(navLink => {
          navLink.classList.toggle('child-expanded')
        })
      }
    })
  }

  if(backIcon) {
    backIcon.addEventListener('click', () => {
      Array.from(secondMenus).forEach(secondMenu => {
        secondMenu.classList.remove('show')
      })
    })
  }

  if(back) {
    back.addEventListener('click', () => {
      Array.from(subnavDropdowns).forEach(subnavDropdown => {
        subnavDropdown.classList.toggle('child-expanded')
      })

      Array.from(navItems).forEach(navItem => {
        navItem.classList.toggle('child-expanded')
      })

      Array.from(navLinks).forEach(navLink => {
        navLink.classList.toggle('child-expanded')
      })
    })
  }

  if(originals) {
    Array.from(originals).forEach(original => {
      original.addEventListener('click', (e) => {
        Array.from(secondMenus).forEach(secondMenu => {
          if(secondMenu !== e.target.parentNode.nextSibling) {
            secondMenu.classList.remove('show')
          }
          if(languageDropdown)
            languageDropdown.classList.remove('open')
        })
      })
    })
  }


  if (subnavDropdowns && menus) {
    if (window.innerWidth > 992) {
      Array.from(subnavDropdowns).forEach((subnavDropdown, index) => {
        const menu = menus[index]
        const uniqueClass = 'menu-' + index
        menu.classList.add(uniqueClass)
        subnavDropdown.addEventListener('mouseover', () => {
          const targetMenu = document.querySelector(`.${uniqueClass}`)
          targetMenu.classList.add('open')
          targetMenu.classList.remove('closed')
          if(accountDropdown) {
            accountDropdown.classList.remove('show')
          }
          if(phoneMenu) {
            phoneMenu.classList.remove('show')
          }
        })
        subnavDropdown.addEventListener('mouseout', (e) => {
          const relatedTarget = e.relatedTarget || e.toElement
          if (!subnavDropdown.contains(relatedTarget) && relatedTarget !== menu) {
            const targetMenu = document.querySelector(`.${uniqueClass}`)
            targetMenu.classList.remove('open')
            targetMenu.classList.add('closed')
            if(accountDropdown) {
              accountDropdown.classList.remove('show')
            }
            if(phoneMenu) {
              phoneMenu.classList.remove('show')
            }
          }
        })
      })
    }
  }

  if(subnavBack) {
    subnavBack.addEventListener('click', (event) => {
      event.preventDefault()
    })
  }

  if(search) {
    search.addEventListener('click', () => {
      phoneDropdown.classList.remove('open')
      searchDropdown.classList.toggle('open')
      languageDropdown.classList.remove('open')
      if(accountDropdown) {
        accountDropdown.classList.remove('show')
      }
    })
  }

  if(phone) {
    phone.addEventListener('click', () => {
      phoneDropdown.classList.toggle('open')
      searchDropdown.classList.remove('open')
      if(languageDropdown)
        languageDropdown.classList.remove('open')
      if(accountDropdown) {
        accountDropdown.classList.remove('show')
      }
    })
  }

  if(language) {
    language.addEventListener('click', () => {
      phoneDropdown.classList.remove('open')
      searchDropdown.classList.remove('open')
      languageDropdown.classList.toggle('open')
      if(accountDropdown) {
        accountDropdown.classList.remove('show')
      }
      Array.from(secondMenus).forEach(secondMenu => {
        secondMenu.classList.remove('show')
      })
    })
  }
  
  if(account) {
    account.addEventListener('click', () => {
      phoneDropdown.classList.remove('open')
      searchDropdown.classList.remove('open')
      if(languageDropdown)
        languageDropdown.classList.remove('open')
      if(accountDropdown) {
        accountDropdown.classList.toggle('show')
      }
    })
  }

  
  if(subnavDropdowns && window.innerWidth < 992) {
    Array.from(subnavDropdowns).forEach(subNavDropdown => {
      if(!supercars() && !sglmg()) {
        subNavDropdown.addEventListener('click', () => {
          Array.from(navLabels).forEach(navLabel => {
            navLabel.classList.toggle('d-none')
          })
          if(contact) {
            Array.from(contact).forEach(contact => {
              contact.classList.toggle('d-none')
            })
          } 
          Array.from(mobileAccount).forEach(mobileAccount => {
            mobileAccount.classList.toggle('d-none')
          })
          Array.from(navbarItems).forEach(navbarItem => {
            if(navbarItem.innerHTML === 'About Us') {
              navbarItem.classList.toggle('d-none')
            }
          })
        })
      }
    })
  }

  if(navbarToggler && window.innerWidth < 992) {
    navbarToggler.addEventListener('click', () => {
      setTimeout(() => { // Delaying the execution
        if(!middleNav.className.includes('show')) {
          Array.from(navLabels).forEach(navLabel => {
            navLabel.classList.remove('d-none')
          })
          if(contact) {
            Array.from(contact).forEach(contact => {
              contact.classList.remove('d-none')
            })
          } 
          Array.from(mobileAccount).forEach(mobileAccount => {
            mobileAccount.classList.remove('d-none')
          })
          Array.from(navbarItems).forEach(navbarItem => {
            if(navbarItem.innerHTML === 'About Us') {
              navbarItem.classList.remove('d-none')
            }
          })
        }
      }, 500)
    })
  }
  
})
